import * as React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/layout';

const TagsPage = ({data: {allMdx: {group}, site: {siteMetadata: {title}}}, pageContext})  => {
  
  return (
    <Layout title="Tags" pageContext={pageContext}>
      <section className="section">
        <div className="container">
          <h1 className="title">Alle Tags</h1>
          
          <div className="columns offer-overview-columns is-multiline">
          {
            group.map((tag) => (
              <div className="column is-half">
                <Link className="button offer-button" to={`/blog/tags/${tag.fieldValue}`}>
                  <span className="is-uppercase">{tag.fieldValue} ({tag.totalCount})</span>
                  <span className="triangle-right"></span>
                </Link>
              </div>
            ))
          }
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMdx {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;

export default TagsPage;
